.App {
  text-align: center;
  width: 100vw;
  /* height: 100vw; */
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

h1 {
  color: white;
}
p {
  color: white;
}

.container {
  margin: auto;
  /* position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%); */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

.content {
  /* background-color: currentcolor; */
  /* background-color: rgba(144,144,144,0.3); */
  /* padding: 1rem; */
  text-shadow: 0.05rem 0.05rem 0.05rem #000000;
}
footer {
  position:fixed;
  bottom:0;
  width:100%;
  /* padding-bottom: 2rem; */
}

footer > * {
  margin: 1rem;
}
