body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./images/cosmic-timetraveler-LhDWW8PhPoE-unsplash.jpg'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  /* opacity: 1.0; */
  -webkit-transition: background 1.5s linear;
  -moz-transition: background 1.5s linear;
  -o-transition: background 1.5s linear;
  -ms-transition: background 1.5s linear;
  transition: background 1.5s linear;
  /* background-position: 50%; */
  /* Chrome, Safari, Opera */
  /* -webkit-animation: myfirst 5s ;
  animation: myfirst 5s ; */
}

/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
  from {opacity: 0;}
  to {opacity: 1;}
}

/* Standard syntax */
@keyframes myfirst {
  from {opacity: 0;}
  to {opacity: 1;}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
