.formrow {
  display: flex;
  text-align: center;
  justify-content: center;
  /* align-items: center; */
  /* margin: auto; */
}

.field-group {
  display: flex;
  /* width: 500px; */
  text-align: center;
  justify-content: center;
  height: 100%;
  /* margin: auto; */
}

.inputclass {
  width: 100%;
}

/* .formrow div:first-child { margin-left: auto; margin-right: 2%; }

.formrow div:last-child { margin-right: auto; margin-left: 2%; } */

.field-group > * > legend {
  font-weight: bold;
  margin-bottom: 10px;
}

.field-group > * > label {
  padding: 8px 0;
}

.field-group > * > input, textarea {
  border: 1px solid #e6e6e6;
  padding: 8px 8px;
  border-radius: 3px;
  /* width: 100%; */
}

.field-group > button {
  border-radius: 3px;
  /* border: 1px solid #3f51b5; */
  border: 1px solid #00FF41;
  /* background: #3f51b5; */
  background: #003B00;
  color: #fff;
  padding: 8px 10px;
  cursor: pointer;
  width: 100%;
}

.submit {
  margin-left: 1rem;
}

.alert {
  padding: 8px 0;
  /* margin: 4px; */
  background-color: #f8f9fa;
  border-radius: 3px;
  width: 100%;
  max-width: 20rem;
}

.alert-danger {
  background-color: #dc3545!important;
}

.alert-warning {
  background-color: #ffc107!important;
}
.alert-success {
  background-color: #28a745!important;
}

.form-container {
  background-color: rgba(144,144,144,0.6);
  padding: 2rem;
}

@media only screen and (min-width: 600px) {
  .form-container {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
